<template>
    <div v-if="promotions">
        <big-banner-comp />
        <!-- <poster-comp /> -->
        <product-list-comp :isVIP="isVIP" filter="new_arrival" />
        <product-list-comp 
            v-for="promotion in promotions" 
            :key="promotion.id" 
            :isVIP="isVIP" 
            filter="promotion" 
            :promotion="promotion"
            layout="big_thumbnail" 
        />
        <!-- <product-list-comp :isVIP="isVIP" filter="hot_items" /> -->
        <brand-comp />
        <category-comp />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BigBannerComp from '../../../components/homepage/BigBanner'
import ProductListComp from '../../../components/homepage/ProductList'
import PosterComp from '../../../components/homepage/Poster'
import BrandComp from '../../../components/homepage/Brand'
import CategoryComp from '../../../components/homepage/Category'

export default {
    data(){
        return {
            isVIP: false,
        }
    },
    components: {
        BigBannerComp,
        ProductListComp,
        PosterComp,
        BrandComp,
        CategoryComp
    },
    computed: {
        ...mapGetters(['currentCustomer', 'promotions']),
    },
    async mounted() {
        //Set all price to 0.00 if customer type == vip
        if(this.currentCustomer){
            if(this.currentCustomer.user.customer.cust_type == 'vip') this.isVIP = true
            else this.isVIP = false
        }
    },
}
</script>
<template>
    <div id="poster-comp" class="mt-md-5 mt-sm-2">
        <div class="container">
            <div class="text-center mb-4">
                <span class="title">{{ mainTitle }}</span>
                <!-- <span class="subtitle"><router-link :to="productsPath">See All</router-link></span> -->
            </div>

            <LightGallery
                :images="images"
                :index="index"
                :disable-scroll="true"
                @close="index = null"
            />
            <div id="galerry-thumb" class="row text-center">
                <div class="col-md-2 col-6 mb-md-0 mb-2"
                    v-for="(thumb, thumbIndex) in [
                    '/img/cny_poster_1_thumb.png',
                    '/img/cny_poster_2_thumb.png',
                    '/img/cny_poster_3_thumb.png',
                    '/img/cny_poster_4_thumb.png',
                    '/img/cny_poster_5_thumb.png',
                    '/img/cny_poster_6_thumb.png',
                    ]"
                    :key="thumbIndex"
                    @click="index = thumbIndex"
                >
                    <img :src="thumb">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { LightGallery } from 'vue-light-gallery';
export default {
    components: {
        LightGallery,
    },
    data() {
      return {
        mainTitle: 'CNY Promo 2021',
        images: [
          { title:'CNY Poster 1', url: '/img/cny_poster_1.png' },
          { title:'CNY Poster 2', url: '/img/cny_poster_2.png' },
          { title:'CNY Poster 3', url: '/img/cny_poster_3.png' },
          { title:'CNY Poster 4', url: '/img/cny_poster_4.png' },
          { title:'CNY Poster 5', url: '/img/cny_poster_5.png' },
          { title:'CNY Poster 6', url: '/img/cny_poster_6.png' },
        ],
        index: null,
      }
    },
}
</script>

<style>
#poster-comp {
    /*font-family: "Roboto Slab",serif;*/
    color: #000;
    /* background-color: #e49f9f; */
    margin: 25px auto;
}

span.title {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 16px;
    font-weight: bold;
    display: block;
}

#galerry-thumb img {
    border: 1px solid #7e97ae;
    border-radius: 0px;
    padding: 1px;
    cursor: pointer;
}
</style>
<template>
    <div class="text-center">
        <vueper-slides id="dekstop-slider" fade autoplay :touchable="false">
            <vueper-slide
                v-for="(slide, i) in desktopSlides"
                :key="i"
                :image="slide.img"
                :style="slide.style"
                :link="'/store/products'" />
        </vueper-slides>
        <vueper-slides id="mobile-slider" autoplay fade :touchable="false">
            <vueper-slide
                v-for="(slide, i) in mobileSlides"
                :key="i"
                :image="slide.img"
                :style="slide.style"
                :link="'/store/products'" />
        </vueper-slides>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

import API from './../../utils/API'

export default {
    components: {
        VueperSlides,
        VueperSlide
    },
    data(){
        return {
            desktopSlides: [
                // { img: '/img/cny_banner_1.png' },
                // { img: '/img/cny_banner_2.png' },
                // { 
                //     img: '/img/top_banner_2.png',
                //     style: 'background-color:rgb(255 127 127)'
                // },
                { 
                    img: '/img/top_banner_1.png', 
                    style: 'background-color:rgb(233 244 252)'
                }
                // { 
                //     img: '/img/raya_banner.png', 
                //     style: 'background-color:#fffde9' 
                // },
                // { 
                //     img: '/img/men_health_banner.png', 
                //     style: 'background-color:#ffffff' 
                // },
                // { img: '/img/raya_limited_banner.png' }
            ],
            mobileSlides: [
                // { img: '/img/cny_banner_1_mobile.jpg' },
                // { img: '/img/cny_banner_2_mobile.jpg' },
                // { img: '/img/top_banner_2_mobile.jpg' },
                { 
                    img: '/img/top_banner_1_mobile.jpg',
                    style: 'background-color:rgb(247 251 254)'
                },
                // { img: '/img/raya_banner_mobile.png' },
                // { img: '/img/men_health_banner.png' },
                // { img: '/img/raya_limited_banner_mobile.png' }
            ]
        }
    },
    computed: {
        ...mapGetters(['promotions']),
    },
    mounted(){
        //if promotion exist
        if(this.promotions.length > 0){
            this.promotions.forEach(promo => {
                if(promo.banner_desktop){
                    this.desktopSlides.push({
                        img: API.getAPIUrl() + promo.banner_desktop.url, 
                        style: `background-color:${promo.banner_bg_code_color}`
                    })
                }
                if(promo.banner_mobile){
                    this.mobileSlides.push({
                        img: API.getAPIUrl() + promo.banner_mobile.url, 
                        style: `background-color:${promo.banner_bg_code_color}`
                    })
                }
            })
        }
    }
}
</script>

<style>
.vueperslides__parallax-wrapper {
    height: 315px!important;
    padding-bottom: unset!important;
}

.vueperslides__track-inner a {
    background-repeat: no-repeat;
    background-size: contain;
    /* background-color: #fffde9; */
}

@media (min-width: 844px) {
    #dekstop-slider {
        display: block;
    }
    #mobile-slider {
        display: none;
    }
}
@media (max-width: 843px) {
    #dekstop-slider {
        display: none;
    }
    #mobile-slider {
        display: block;
    }
}
@media (max-width: 450px) {
    .vueperslides__parallax-wrapper {
        height: 210px!important;
        padding-bottom: unset!important;
    }
}
</style>
<template>
    <div v-if="products" id="new-arrival-comp" class="container mt-5">
        <div class="text-center">
            <span class="title">{{ mainTitle }}</span>
            <span class="subtitle"><router-link :to="productsRoute">See All</router-link></span>
        </div>
        <div v-if="layout && layout == 'big_thumbnail'" id="big-thumbnail-layout" class="contents mt-4">
            <div class="row" v-if="filter != 'promotion'">
                <div v-for="product in products" :key="product.id" class="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
                    <div class="row mb-3">
                        <div class="col-md-12 bg-white">
                            <!-- <router-link :to="`/store/products/${product.id}`"> -->
                            <div class="img-box" :style="{'background-image': `url(${getImageLink(product)})`}">
                                <img :src="getPromoBadge(product)" alt="" class="badge-img">
                            </div>
                            <div v-if="product.is_sold_out" class="img-bg-sold-out"></div>
                            <button v-if="!product.is_sold_out" class="btn" @click="addItemObj(product)">Add to Cart</button>
                            <button v-else class="btn btn-sold-out" >Sold Out</button>
                            <!-- </router-link> -->
                        </div>
                        <div class="col-md-12 text-center prod-label">
                            <span class="name"><router-link :to="`/store/products/${product.id}`">{{ product.title }}</router-link></span>
                            <div v-if="!isVIP">
                                <!-- <span v-if="product.variants[0].compare_at_price && product.variants[0].compare_at_price != 0" class="price compare_at_price">RM {{ (product.variants[0].compare_at_price).toFixed(2) }}</span>
                                <span class="price">RM {{ (product.variants[0].price).toFixed(2) }}</span> -->
                                <div v-if="product.promo_price">
                                    <span class="price compare_at_price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                    <span class="price">RM {{ (product.promo_price).toFixed(2) }}</span>
                                </div>
                                <div v-else>
                                    <span class="price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                </div>
                            </div>
                            <span v-else class="price">RM 0.00</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div v-for="productPromo in products" :key="productPromo.id" class="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
                    <div class="row mb-3">
                        <div class="col-md-12 bg-white">
                            <!-- <router-link :to="`/store/products/${productPromo.product.id}`"> -->
                            <div class="img-box" :style="{'background-image': `url(${getImageLink(productPromo.product)})`}">
                                <img :src="getPromoBadge(productPromo.product)" alt="" class="badge-img">
                            </div>
                            <div v-if="productPromo.product.is_sold_out" class="img-bg-sold-out"></div>
                            <button v-if="!productPromo.product.is_sold_out" class="btn" @click="addItemObj(productPromo.product)">Add to Cart</button>
                            <button v-else class="btn btn-sold-out" >Sold Out</button>
                            <!-- </router-link> -->
                        </div>
                        <div class="col-md-12 text-center prod-label">
                            <span class="name"><router-link :to="`/store/products/${productPromo.product.id}`">{{ productPromo.product.title }}</router-link></span>
                            <div v-if="!isVIP">
                                <!-- <span v-if="productPromo.product.variants[0].compare_at_price && productPromo.product.variants[0].compare_at_price != 0" class="price compare_at_price">RM {{ (productPromo.product.variants[0].compare_at_price).toFixed(2) }}</span>
                                <span class="price">RM {{ (productPromo.product.variants[0].price).toFixed(2) }}</span> -->
                                <div v-if="productPromo.product.promo_price">
                                    <span class="price compare_at_price">RM {{ (productPromo.product.variants[0].price).toFixed(2) }}</span>
                                    <span class="price">RM {{ (productPromo.product.promo_price).toFixed(2) }}</span>
                                </div>
                                <div v-else>
                                    <span class="price">RM {{ (productPromo.product.variants[0].price).toFixed(2) }}</span>
                                </div>
                            </div>
                            <span v-else class="price">RM 0.00</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else id="small-thumbnail-layout" class="contents mt-4">
            <div class="row" v-if="filter != 'promotion'">
                <div v-for="product in products" :key="product.id" class="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
                    <div class="row mb-3">
                        <div class="col-md-6 mb-3 bg-white">
                            <!-- <router-link :to="`/store/products/${product.id}`"> -->
                            <div class="img-box" :style="{'background-image': `url(${getImageLink(product)})`}">
                                <img :src="getPromoBadge(product)" alt="" class="badge-img">
                            </div>
                            <div v-if="product.is_sold_out" class="img-bg-sold-out"></div>
                            <button v-if="!product.is_sold_out" class="btn" @click="addItemObj(product)">Add to Cart</button>
                            <button v-else class="btn btn-sold-out" >Sold Out</button>
                            <!-- </router-link> -->
                        </div>
                        <div class="col-md-6 text-md-left text-center prod-label">
                            <span class="name"><router-link :to="`/store/products/${product.id}`">{{ product.title }}</router-link></span>
                            <div v-if="!isVIP">
                                <div v-if="product.promo_price">
                                    <span class="price compare_at_price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                    <span class="price">RM {{ (product.promo_price).toFixed(2) }}</span>
                                </div>
                                <div v-else>
                                    <span class="price">RM {{ (product.variants[0].price).toFixed(2) }}</span>
                                </div>
                            </div>
                            <span v-else class="price">RM 0.00</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div v-for="productPromo in products" :key="productPromo.id" class="col-lg-3 col-md-6 col-sm-6 col-6 mb-3">
                    <div class="row mb-3">
                        <div class="col-md-6 mb-3 bg-white">
                            <!-- <router-link :to="`/store/products/${productPromo.product.id}`"> -->
                            <div class="img-box" :style="{'background-image': `url(${getImageLink(productPromo.product)})`}">
                                <img :src="getPromoBadge(productPromo.product)" alt="" class="badge-img">
                            </div>
                            <div v-if="productPromo.product.is_sold_out" class="img-bg-sold-out"></div>
                            <button v-if="!productPromo.product.is_sold_out" class="btn" @click="addItemObj(productPromo.product)">Add to Cart</button>
                            <button v-else class="btn btn-sold-out" >Sold Out</button>
                            <!-- </router-link> -->
                        </div>
                        <div class="col-md-6 text-md-left text-center prod-label">
                            <span class="name"><router-link :to="`/store/products/${productPromo.product.id}`">{{ productPromo.product.title }}</router-link></span>
                            <div v-if="!isVIP">
                                <div v-if="productPromo.product.promo_price">
                                    <span class="price compare_at_price">RM {{ (productPromo.product.variants[0].price).toFixed(2) }}</span>
                                    <span class="price">RM {{ (productPromo.product.promo_price).toFixed(2) }}</span>
                                </div>
                                <div v-else>
                                    <span class="price">RM {{ (productPromo.product.variants[0].price).toFixed(2) }}</span>
                                </div>
                            </div>
                            <span v-else class="price">RM 0.00</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '../../utils/API'
import { mapMutations, mapGetters } from 'vuex'
import { checkProductPromo } from './../../utils/ProductUtils'

export default {
    props: ['filter', 'layout', 'isVIP', 'promotion'],
    data(){
        return {
            products: null,
            mainTitle: null,
        }
    },
    computed: {
        ...mapGetters(['promotions']),
    },
    methods: {
        ...mapMutations(['addItem']),
        addItemObj(_product){
            this.addItem({ product: _product , quantity: 1 }) //default value add of quantity is 1
        },
        initPromo(){
            this.products.forEach(product => {
                let prodPromo = checkProductPromo(product)
                if(prodPromo) { //craete new subproperties if promo exist
                    console.log('yeah')
                    product.promo_price = prodPromo.promo_price
                    product.promo_badge_url = prodPromo.promo_badge
                }
            })
        },
        async fetchData(){
            if(this.filter == 'new_arrival') {
                this.mainTitle = 'New Arrivals'
                let productsPath = '/products?filter=new_arrival&_limit=8&ishomepage=true'
                this.productsRoute = '/store/products?filter=new_arrival'

                let res = await API.get(productsPath)
                this.products = res.data.products
            }
            if(this.filter == 'promotion') {
                this.mainTitle = `${this.promotion.title} Promotion`
                this.productsRoute = `/store/products?filter=promotion&promoid=${this.promotion._id}`
                this.products = this.promotion.product_promos.slice(0,4)
            }
            // if(this.filter == 'hot_items') {
            //     this.mainTitle = 'Hot Items'
            //     let productsPath = '/products?filter=hot_items&_limit=8&ishomepage=true'
            //     this.productsRoute = '/store/products?filter=hot_items'

            //     let res = await API.get(productsPath)
            //     this.products = res.data.products
            // }
        },
        getImageLink(_product) {
            if(!_product.variants[0].image || _product.variants[0].image.length == 0) return '/img/default_no_image.jpg'
            else return API.getAPIUrl() + _product.variants[0].image[0].url //get the first image
        },
        getPromoBadge(_product){
            if(_product.promo_badge_url) return API.getAPIUrl() + _product.promo_badge_url
            // if(_product.is_promotion){
            //     if(_product.promo_type == 'raya') return '/img/badge_raya_promo.png'
            //     else if(_product.promo_type == 'limitedtime') return '/img/badge_4_days_only.png'
            //     else if(_product.promo_type == 'other') return '/img/badge_other.png'
            // }
        }
    },
    async mounted(){
        await this.fetchData()

        //check product promo
        await this.initPromo()
    }
}
</script>

<style scoped>
#new-arrival-comp {
    /*font-family: "Roboto Slab",serif;*/
    color: #000;
    /* background-color: #e49f9f; */
    margin: 25px auto;
}

#big-thumbnail-layout .prod-label {
    margin-top: -25px;
}

span.title {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 16px;
    font-weight: bold;
    display: block;
}

span.subtitle {
    display: block;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 12px;
}
span.subtitle a {
    color: #666;
}
span.subtitle a:hover {
    color:#000;
    text-decoration: unset;
}

.contents {
    position: relative;
}

.contents button {
    opacity: 0;
    width: 100%;
    position: relative;
    bottom: 50px;
    padding: 10px 10px;
    display: block;
    background-color: #fff;
    color: #000;
    border: unset;
    border-radius: unset;
    text-transform: uppercase;
    font-size: 14px!important;
    font-weight: bold;
    /* font-family: "Karla", sans-serif; */
}

.contents button:hover {
    opacity: 100;
    transition: 0.2s;
    background-color: #000;
    color: #fff;
}

.contents button.btn-sold-out:hover {
    opacity: 100;
    transition: 0;
    background-color: #fff;
    color: #a91a18;
    cursor:initial;
}

.contents button.btn-sold-out {
    color: #a91a18;
    opacity: 1;
}

.contents .img-box {
    position: relative;
    display: block;
    padding-top: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.contents .img-box img.badge-img {
    position: absolute;
    width: 50%;
    top: 5px;
}

.contents .img-box:hover+button {
    opacity: 100;
    transition: 0.2s;
}

.contents .name, .price {
    display: block;
    line-height: 1.2rem;
    margin-bottom: 5px;
}
.contents .name a {
    color:#666;
    text-decoration: unset;
    font-size: 14px;
    font-weight: bold;
}
.contents .price {
    color: #a91a18;
    font-weight: bold;
    font-size: 14px;
}
.contents .price.compare_at_price {
    text-decoration: line-through;
    color: gray;
    font-size: 12px;
    margin-bottom: -3px;
}


/* div[class^="col-"] {
    background-color: red;
} */

@media (max-width: 767px) {
    #small-thumbnail-layout .prod-label {
        margin-top: -35px;
    }
}
</style>